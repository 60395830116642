import { BannerSlots, Buttons, CarouselProducts, FeaturedProducts, FiveSlotBanner, SingleBanner, TextHeadline, } from "@/Slice";
import { MarketingMessage } from "@/Slice/MarketingMessage";
import { TwoSlotHomepageBanner } from "@/Slice/TwoSlotHomepageBanner";
import { VideoBanner } from "@/Slice/VideoBanner";
import { Container, ErrorBoundary } from "@/components";
export const SliceRender = ({ slices }) => (<div>
        {[...slices]
        .filter((slice) => !slice.primary.is_hide_module)
        .map((slice, index) => (<Slice key={index} slice={slice}/>))}
    </div>);
const BASE_MARGIN = "mt-2 first:mt-0";
const SliceContainer = ({ SliceComponent, slice, isFullWidth, }) => (<Container className={BASE_MARGIN} isFullWidth={isFullWidth}>
        <ErrorBoundary system="CMS">
            <SliceComponent slice={slice}/>
        </ErrorBoundary>
    </Container>);
export const Slice = ({ slice }) => {
    switch (slice.slice_type) {
        case "video_banner": {
            return (<SliceContainer slice={slice} SliceComponent={VideoBanner} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "single_banner": {
            return (<SliceContainer slice={slice} SliceComponent={SingleBanner} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "text_headline": {
            return <SliceContainer slice={slice} SliceComponent={TextHeadline}/>;
        }
        case "two_slot_banner":
        case "three_slot_banner":
        case "four_slot_banner":
        case "six_slot_banner": {
            return (<SliceContainer slice={slice} SliceComponent={BannerSlots} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "five_slot_banner": {
            return (<SliceContainer slice={slice} SliceComponent={FiveSlotBanner} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "featured_products": {
            return (<SliceContainer slice={slice} SliceComponent={FeaturedProducts} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "carousel_products": {
            return (<SliceContainer slice={slice} SliceComponent={CarouselProducts} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "marketing_message": {
            return <SliceContainer SliceComponent={MarketingMessage} slice={slice}/>;
        }
        case "two_slot_homepage_banner": {
            return (<SliceContainer SliceComponent={TwoSlotHomepageBanner} slice={slice} isFullWidth={slice.primary.is_full_width}/>);
        }
        case "buttons": {
            return <SliceContainer SliceComponent={Buttons} slice={slice}/>;
        }
        default: {
            return <></>;
        }
    }
};
